export default {
  init() {
    const ITEMS = document.querySelectorAll('.navbar-brand>.navbar-item, .navbar-end>.navbar-item, .iko-hero__btn');
    let sections = document.querySelectorAll('h2.iko-headline, #top');
    let sectionData = [];

    window.addEventListener('scroll', e => {
      sections.forEach(section => {
        sectionData.push({
          bounding: section.getBoundingClientRect(),
          id: section.id,
        });
      });
      sectionData.forEach(section => {
        if (section['bounding'].top <= 70) {
          ITEMS.forEach(item => {
            if (item.hash.split('#')[1] == section.id && item.classList.contains('navbar-item')) {
              const ACTIVE_ITEM = document.querySelector('.navbar-item.is-active');
              if (ACTIVE_ITEM) {
                ACTIVE_ITEM.classList.remove('is-active');
              }
              item.classList.add('is-active');
            }
          });
        }
      });

      if (location.pathname == '/') {
        check_if_last_item();
      }

    });

    ITEMS.forEach(item => {
      handle_active_state(item);
    });

    if (document.querySelector('.navbar').classList.contains('--primary')) {
      check_if_last_item();
    }

    function handle_active_state(item) {
      item.addEventListener('click', e => {
        const ACTIVE_ITEM = document.querySelector('.navbar-item.is-active');

        if (ACTIVE_ITEM) {
          ACTIVE_ITEM.classList.remove('.is-active');
        }

        item.classList.add('is-active');
      });
    }

    /// Responsive Navigation ///
    // Get all "navbar-burger" elements
    const NAVBAR_BURGER = document.querySelector('.navbar-burger');

    // Check if there are any navbar burgers
    if (NAVBAR_BURGER) {
      NAVBAR_BURGER.addEventListener('click', () => {
        // Get the target from the "data-target" attribute
        const DS_TARGET = NAVBAR_BURGER.dataset.target;
        const EL_TARGET = document.getElementById(DS_TARGET);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        NAVBAR_BURGER.classList.toggle('is-active');
        EL_TARGET.classList.toggle('is-active');

        // hideOnClickOutside( EL_TARGET );

        window.addEventListener('click', e => {
          if (EL_TARGET.classList.contains('is-active') && !e.target.classList.contains('navbar-burger') && !e.target.parentElement.classList.contains('navbar-burger') && e.target.id !== 'search' && !e.target.classList.contains('la-search')) {
            EL_TARGET.classList.toggle('is-active');
            NAVBAR_BURGER.classList.toggle('is-active');
          }
        });
      });
    }

    function check_if_last_item() {

      if ((window.innerHeight + window.scrollY + 20) >= document.body.offsetHeight) {
        const NAVBAR_ITEMS = [];

        ITEMS.forEach(item => {
          if (item.classList.contains('navbar-item') && item.hash) {
            NAVBAR_ITEMS.push(item);
          }
        });

        const ACTIVE_ITEM = document.querySelector('.navbar-item.is-active');
        ACTIVE_ITEM.classList.remove('is-active');

        NAVBAR_ITEMS[NAVBAR_ITEMS.length - 1].classList.add('is-active');

      }
    }
  }
}